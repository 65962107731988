<template>
  <div v-if="items">
    <ScannedListItem
      v-for="(item, index) in items"
      :key="index"
      :success="item.success"
      :warning="getWarning(item)"
      :scannedValue="item.scanned_value"
      :recipient="item.recipient"
      :status="item.human_result"
      :subStatus="getSubStatus(item)"
    >
      <template v-slot:center>
        <p v-if="item.shelf_code" class="d-flex align-items-center">
          <shelf-icon />
          <strong>{{ item.shelf_code }}</strong>
        </p>
      </template>
    </ScannedListItem>
  </div>
</template>

<script>
import ShelfIcon from "vue-material-design-icons/FileCabinet.vue";
import ScannedListItem from "./Item";

export default {
  name: "ScannedListInventoryItems",
  components: { ShelfIcon, ScannedListItem },
  props: {
    items: {
      type: [Array, null],
      default: null
    }
  },

  methods: {
    getWarning(item) {
      return !!item.scanned_count;
    },

    getSubStatus(item) {
      return item.scanned_count ? item.scanned_count_text : null;
    }
  }
};
</script>
